/* src/Components/Preloader.css */
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 0.5s ease-out;
  }
  
  .preloader.fade-out {
    opacity: 0;
  }
  .loader_img{
    border-radius: 4px;
    animation: loader 1.1s linear infinite;
  }
  .loader_imgs{
    border-radius: 60px;
    animation: loader 2.2s linear infinite;
    margin-left: 50px;
    font-size: 40px;
  }
  @keyframes loader{
    0%{
      transform: rotate(0deg);
    }
    25%{
        transform: rotate(60deg);
      }
      50%{
        transform: rotate(140deg);
      }
      75%{
        transform: rotate(180deg);
      }
    100%{
     transform: rotate(360deg);
    }

}

  @keyframes loader{
       0%{
         transform: rotate(0deg);
       }
       100%{
        transform: rotate(360deg);
       }

  }

  @media (max-width:767px) {
    .loader_imgs{
      border-radius: 60px;
      animation: loader 2.2s linear infinite;
      margin-left: 50px;
      font-size: 30px;
    }
    
  }
  