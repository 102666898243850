* {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.main {
  width: 100%;
  height: auto;
}
video {
  width: 100%;
  height: 100vh;
  margin-top: 10vh;
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
  margin: 0;
}

.contents{
  position: absolute;
  width: 100%;
  height: 70%;
  justify-content: center;
  align-items: center;
  /* top: 267px; */
  top: 200px;
  padding: 0px 11%;
  text-align: justify;
  font-size: 1.3rem;
  overflow: hidden;
  flex-direction: column;
  color: rgb(0, 0, 0);
  width: 88%;
 
}
.contents button{
  box-shadow: 2px 2px 10px black;
  position: relative;
  font-family: "system-ui";
  top: 0px;
}
.contents button:hover{
  background-color: #2A55EF;
  color: white;
}
/* .contents h1{
  color: rgb(0, 0, 0);
  position: relative;
  font-family: "system-ui";
  top: 286px;
  width: 50%;
  font-size: medium;
} */

.contents h1 {
  color: rgb(255 255 255);
  position: relative;
  font-family: "system-ui";
  bottom: 0px;
  top:60px;
  left:-130px;
  right: 400px;
  width: 50%;
  font-size: 2.2rem;
  font-weight: 700;
  list-style-position: inherit;
  text-align:justify;
}
.enqiure_btn{
  text-decoration: none;
  color: white;
}
.btn_exp {
  margin-top: 10vh;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
  font-weight: 600;
  color: black;
  border: none;
}
.iconss {
  background-color:#3489D4;
  border:2px solid black;
  padding: 18px 10px;
  width: 2.6rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  gap: 4px;
  font-size: 1rem;
  color: white;
  z-index: 1;
  border-right: none;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 40%;
}
.faw {
  writing-mode: vertical-lr;
}

@media (max-width: 767px) {
  .main {
    width: 100%;
    height: auto;
  }
  video {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-size: cover;
    overflow: hidden;
    margin-top: 8vh;
  }
  .iconss {
    background-color: rgb(0, 46, 87);
    padding: 20px 10px;
    width: 35px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    gap: 4px;
    font-size: 14px;
    color: white;
    z-index: 1;
    border-right: none;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 40%;
  }
  .btn_exp{
    margin-top: 0vh;
    padding: 3px 4px;
    cursor: pointer;
    font-size:9px;
    border-radius: 25px;
    font-weight: 600;
    background-color: rgb(228, 12, 30);
    border: none;
    padding: 10px;
    color: white;
  }

  .btn_exp:hover{
    background-color:  #5972f1;
    color: white;
    transition: 0.5s;
  }
  .contents{
    width: 100%;
    top: 140px;
    padding: 0px 12%;
    font-size: 13px;
    text-align: none;
    overflow: hidden;
    flex-direction: column;
    color: white;
    width: 90%;
  }
  .contents h1{
    color: white;
    font-size: 14.5px;
    line-height: 2.6vh;
  }
}
