
.assement_wrapper{
    height: 86vh;
    width: 100%;
    margin: 0px;
    box-shadow: -15px -15px 15px rgba(255, 255, 255, 0.5),
    15px 15px 15px rgba(0, 0, 0, 0.05),
    inset 4px 4px 5px rgba(255, 255, 255, 0.8),
    inset -4px -4px 5px rgba(0, 0, 0, 0.05), 0px 50px 40px rgba(0, 0, 0, 0.05);
}
/* main^ */
.assess_flex{
    display: flex;
    flex-wrap: wrap;
    margin-top: 7vh;
    gap: 20px;
    padding: 0px 90px;
    cursor: pointer;
}
/* icons */
.right_icons{
    height:30vh;
    width: 4%;
    background-color: red;
    padding: 10px 20px;
    border-radius: 4px;
    border: 2px solid red;
}
.Enquire_btn h3{
    word-break: break-all;
    flex-direction: column;
     width: 10%;
     color: white;
}

/* end */

.main_er{
    width: 32%;
    height: auto;
    border-radius: 10px;
    background-color: white;
    border: 1px solid rgb(232, 226, 226); 
}
.main_er:hover{
    transform: translateY(-2%);
    box-shadow: 1px 2px 2px 4px rgb(245, 245, 245);
    transition: 0.6s;
    border: none;
}
.asss_img{
    width: 100%;
    padding: 10px;
    border-radius: 15px;
}
.ass_ti{
    font-size: 0.9rem;
    padding: 15px;
    color: #1e85e5;
    font-weight:600;
    line-height: 20px;
    cursor: pointer;
}
.ass_sub_t{
    font-size: 1rem;
    text-align: justify;
    padding: 0px 21px;
    color: rgb(105, 104, 104);
}
.rebtn{
    color: rgb(0, 141, 144);
    padding: 14px 0px;
    font-size: 1.1rem;
}
.scdule{
    font-size: 1.6rem;
    font-weight: 600;
    color: #272343;
    margin: 30px 0px;
}
@media (max-width:767px) {
    .assement_wrapper{
        height: auto;
        width: 100%;
    }
    /* main^ */
    .assess_flex{
        flex-direction: column;
        gap: 20px;
        cursor: pointer;
        margin-top: 0px;
        padding: 0px;
    }
    .scdule{
        font-size: 30px;
        font-weight: 600;
        color: #00284e;
        margin: 30px 0px;
    }
    .main_er{
        width: 100%;
        height: auto;
        border-radius: 7px;
        padding: 20px;
        background-color: white;
        border: 1px solid rgb(253, 253, 253); 
    }
    .ass_sub_t{
        font-size: 1rem;
        text-align: justify;
        padding: 0px 21px;
        color: rgb(105, 104, 104);
        word-spacing: 0px;
    }
}