.copy_wrapper{
    height: 20%;
    width: 100%;
    /* padding: 30px 0px; */
    color: white;
    background-color: #3489D4;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}
.icso{
    margin-top: 5px;
    display: flex;
    gap: 4px;
    font-size: 1.5rem;
    justify-content: center;
    text-align: center;
    padding: 8px 0px;
    cursor:pointer;
}

.desclamer_tilte{
    font-size: .96rem;
    color: white;
    text-align: center;
    font-weight: 600;
    align-items: center;
    width: 100%;
    position:relative;
    bottom:15px;
    right:230px;
}

.desclamer_div{
    /* height: 20%; */
    width: 100%;
    position: relative;
    top:50px;
}

.lpm{
    padding:5px 10px;
    position:relative;
    /* left:%; */
    bottom:50%;
    left: 300px;
    top:-20px;

}
@media (max-width:767px) {
    .desclamer_tilte{
       font-size: 1rem;
       padding: 0px 22.px;
       justify-content: center;
    }
    .icso{
        margin-top: 5px;
        display: flex;
        gap: 10px;
        font-size: 1.2rem;
        justify-content: center;
        text-align: center;
        padding: 16px 0px;
    }
    .copy_wrapper{
        /* height: 18vh; */
        width: 100%;
        /* padding:28px; */
        color: white;
        background-color: rgb(0, 46, 87);
        border-top: 2px solid white;
        border-bottom: 2px solid white;
    }
}