*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
nav{
  position: fixed;
  z-index: 99;
  width: 100%;
  background:#ffffff;
    opacity: 10; 
}

nav .wrapper{
  position: relative;
  max-width: 1300px;
  padding: 3px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.build{
  padding: 3px 0px;
  font-family: 'system-ui';
  font-weight: 500;
  font-size: 1rem;
}
.flag{
  font-weight: 600;
  gap: 2px;
  font-size: 0.9rem;
  padding: 2px 0px;
}
.pre{
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.9em;
  padding: 4px 0px;
}
.hire_us{
  padding: 4px 27px;
  background-color: #3489D4;
  cursor: pointer;
  border: 0.9px solid white;
  color: white;
  border-radius: 5px;
  font-family: "system-ui";
}
.items{
  display: flex;
  padding: 1.5vh 10px;
  /* background-color: rgb(0, 46, 87); */
  background-color:  rgb(52, 137, 212);
color: rgb(255, 255, 255);
justify-content: space-between;
}
.txts{
  display: flex;
  gap: 20px;
}
.hire_us:hover{
  background-color: #5972f1;
  transition: 1s;
}
.d{
  display: flex;
  justify-content: center;
}
.ico{
  font-size: 1.2rem;
  justify-content: right;
  display: flex;
  gap: 0.3rem;
  justify-content: space-between;
}
.m{
  display: flex;
  justify-content: center;
  padding: 0px 30px;
}
.wrapper .logo a{
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.logo img{
    font-size: 50px;
    margin-top: 28px;
    padding: 15px 0px;
}
.wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.2s ease;
  font-family: system-ui;
}
.nav-links li a:hover{
  background: #fafcff;
  color: rgb(13, 81, 102);
  border-top: 3px solid rgb(88, 216, 255);
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #ffffff;
  border-top: 5px solid rgb(88, 216, 255);
  color: rgb(0, 0, 0);
  width: 250px;
  line-height: 40px;
  top: 80px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  height: 30vh;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content{
  background: #ffffff;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  height: 71vh;
  border-top: 5px solid rgb(88, 216, 255);
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .contents .row{
  width: calc(25% - 30px);
  line-height: 30px;
}
.contents .row header{
  color: #ec0909;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}
.contents .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
  
}
.row .mega-links li{
  padding: 0 20px;
  display: flex;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color: black;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover{
  color: black;
  background-color: #fafcff;
  border-top: 2px solid orange;
}
.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    right: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .items{
    display: none;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}

.fon-img{
  font-family:  monospace;
}