*{
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.Industries_wrapper {
  background-color: #f8f8f8;
}
.Industries {
  font-size: 1.5rem;
  color: rgb(255, 166, 0);
  font-weight: 600;
  margin-top: 4px;
  justify-content: center;
  align-items: center;
  padding: 35px 0px;
}

.we_are {
  color: #272343;
  font-weight: 600;
  margin-top: -10px;
  font-size: 1.8rem;
  padding: 30px 0px;
}
.cards {
  display: flex;
  border-radius: 10px;
margin: 0px;
padding: 0px 0px;
  height: 100px;
  border: 2px solid rgb(236, 236, 236);
  cursor: pointer;
  justify-content: center;
}
.cards h1 {
  width: 150px;
  justify-content: center;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  height: 100px;
  background-color: rgb(255, 255, 255);
}
.cards:hover{
  transform: translateY(-3%);
  transition: 1s;
}
.cards h1:hover{
  background-color: silver;
  color: rgb(255, 0, 0);
  transition: 0.9s;
}
.cards img {
  width: 10%;
  height: auto;
  width: 120px;
  background-color: #fafafa;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  padding: 0.9em;
}
.card_wrapper {
  display: flex;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
  padding: 40px 40px;
}
.serive_title{
  font-size: 1.2rem;
  padding: 6vh 0px;
}
@media (max-width: 767px) {
  .we_are {
    color: #0d314e;
    font-weight: 600;
    padding: 25px 20px;
    margin-top: 0px;
    font-size: 30px;
    text-align: center;
  }

  .Industries_wrapper{
    height: auto;
  }
  .Industries{
    font-size: 1.5rem;
    color: rgb(255, 166, 0);
    font-weight: 600;
    margin-top: 4px;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
  }

  .cards img {
      height:110 auto;
    width: 31%;
    background-color: #f5f5f5;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    padding: 20px;
    border-right: 1px solid rgb(184, 184, 184);
  }
  .cards h1{
    width: 100%;
    font-size: 20px;
    padding: 38.8px 0px;
    justify-content: center;
  }
 .card_wrapper{
  flex-wrap: wrap;
  padding: 16px;
  flex-direction: column;
 }

 
}
