* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.wrapper_div {
    width: 100%;
    height: 100vh;
   
}

.main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left_container {
    margin-bottom: -77px;
    width: 50%;
    height: 77%;
    padding: 20px 2px;
    background-image: url("https://img.freepik.com/free-photo/violet-watercolor-texture-background_1083-172.jpg");
    object-position: center center;
    object-fit: cover;
}

.right_container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .right_container img {
    max-width: 100%;
    height: auto;
} */


/* Contact form CSS */

.email_label{
    font-size: 20px;
    font-family: system-ui;
    font-weight: 500;
}

.contact_image {
    width: 100%;
    max-width: 565px;
    height: auto;
    border-radius: 10px; 
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
    object-fit: cover;
}

.contact_heading {
    margin-bottom: 25px;
    margin-top: 0px;
    font-size: 28px;
    font-weight: 800;
    color: #000000;
}

.contact_form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact_form label {
    font-weight: 500;
    margin-bottom: 5px;
}

.contact_form input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.contact_form button {
    width: 30%;
    position: relative;
    left:250px;
    top:10px;
    padding: 10px;
    background: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
}

.contact_form button:hover {
    background: #0056b3;
}

.contact_form label{
    font-size: 20px;
    font-family: system-ui;
    align-content: left;
}





/* // */

.contact_form input {
    width: 50%;
    position: relative;
    left:180px;
    padding: 10px;
    font-size: 16px; 
    font-family: "Arial", sans-serif;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    outline: .96px solid transparent;
    transition: all 0.3s ease-in-out;
   
    /* box-shadow: 0 2px 4px rgba(0, 86, 179, 0.2); */
}

/* Add focus effect for better user experience */
.contact_form input:focus {
    border-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 86, 179, 0.3); 
}


/* Contact Form Css Ends */

/* Global Presence Heading  */

.Global_presence{
    /* margin-bottom: 25px; */
    margin-top: 0px;
    font-size: 28px;
    font-weight: 800;
    color: #3489D4;
    text-decoration: underline;
}




/* Location Container CSS Starts from here */

.email_bold_location{
    font-size: .96rem;
    font-weight: 500;
    color:#000;
}

.locations-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 48px 16px;
  }
  
  .locations-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
  }
  
  .location-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .location-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 12px;
  }
  
  .location-address {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .location-address p {
    margin: 4px 0;
  }
  
  @media (min-width: 768px) {
    .locations-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .locations-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  

/* Iso Certification Css */

.Iso_heading{
    /* margin-bottom: 25px; */
    margin-top: 0px;
    font-size: 28px;
    font-weight: 800;
    color: #3489D4;
    text-decoration: underline;
}

.Iso_container {
    width: 100%;
    background-color: #fff;
    text-align: center;
}

.ISO\ Certified {
    margin-bottom: 20px;
}

.ISO\ Certified h1 {
    font-size: 28px;
    font-weight: 800;
    color: #3489D4;
}

.iso-div-certification {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iso-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.iso-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    margin: 38px;
}

.certificate_image {
    width: 100%;
    max-height: 380px;
    object-fit: cover;
    border-radius: 5px;
}

.iso-card-title {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}
