.tech_pracv{
  font-size: 1.6rem;
    font-weight: 600;
    color: #00284e;
    margin: 30px 0px;
}
.card_flex{
  display: flex;
  flex-wrap: wrap;
  gap: 33px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
}
.main_card_ui{
  width: 19.5%;
  height: 45vh;
  border-radius: 6px;
  border: 2px solid rgb(231, 231, 231);
  box-shadow: 5px 15px 16px rgb(243, 243, 243);
  overflow: hidden;

}
#technolgy_wrapper{
  height: 115vh;
}
.main_card_ui:hover{
  transition: 0.3s;
    transform: translateY(-3%);
    cursor: pointer;
}
.tech_imgs{
  width: 100%;
  height: 24.9vh;
  padding: 8px;
}
.tech_tiltle{
  text-align: center;
  font-size: 1.3em;
  color: rgb(0, 0, 83);
  font-weight: 500;
  margin: 14px 0px;
}
.tech_nis{
  width: 100%;
  padding: 11px;
  background-color: rgb(255, 255, 255);
  border: none;
  font-size: 1.1rem;
  border-top: 1px solid rgb(218, 218, 218);
  margin: 25px 0px;
  border-radius: 6px;
  cursor: pointer;
  padding: 9px;
}
.tech_nis:hover{
  background-color: #00bbf0;
  color: white;
  transition: 1s;
  transform: translateY(3%);
}
@media (max-width:767px) {
  .tech_pracv{
    font-size: 30px;
      font-weight: 600;
      color: #00284e;
      margin: 30px 0px;
  }
  #technolgy_wrapper{
    height: auto;
  }
  .card_flex{
    gap: 33px;
    flex-direction: column;
  }
  .main_card_ui{
    width: 93%;
    height: auto;
    border-radius: 6px;
    border: 2px solid rgb(231, 231, 231);
    box-shadow: 5px 15px 16px rgb(243, 243, 243);
    overflow: hidden;
    padding: 40px;
  }
  .main_card_ui:hover{
    transition: 0.3s;
      transform: translateY(-3%);
      cursor: pointer;
  }
  .tech_imgs{
    width: 100%;
    height: 24.9vh;
    padding: 2px;
  }
  .tech_tiltle{
    text-align: center;
    font-size: 22px;
    color: rgb(0, 0, 83);
    font-weight: 500;
    margin: 19px 0px;
  }
  .tech_nis{
    width: 100%;
    padding: 25px 0px;
    background-color: rgb(255, 255, 255);
    border: none;
    font-size: 17px;
    border-top: 1px solid rgb(214, 214, 214);
    border-radius: 0px;
    cursor: pointer;
    padding: 9px;
  }
  .tech_nis:hover{
    background-color: rgb(239, 245, 250);
    transition: 1s;
    transform: translateY(3%);
  }
} 

