.about_wrapper{
    height: 78vh;
    background-color:#f2f2f2;
    padding: 10vh;
}
.about_d{
    padding: 28px 0px;
}
.about_1{
    gap: 30px;
    display: flex;
}
.about_2{
    display: flex;
    gap: 30px;
}
.skill_fl{
    width: 134px;
    height: 100px;
    border: 2px solid rgb(244, 244, 244);
    margin-top: 2px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    margin: 10px;
    padding: 6px;
    display: flex;
}
.icon{
    width: 100%;
}
.full_wrapper{
    display: flex;
}
.main_tit{
    font-size: 2rem;
    font-weight: 600;
    width: 90%;
    color: rgb(15, 78, 101);
    line-height: 38px;
    text-align: justify;
}
.about_para{
    width: 90%;
    text-align: justify;
    margin-top: 3vh;
    font-size: 1.1rem;
    color: rgb(99, 99, 99);
}
.para{
    margin-left: 3vw;
    line-height: 27.6px;
    text-align: justify;
}
.rd_m{
    padding: 10px 25px;
    border-radius: 16px;
    color: white;
    border: none;
    margin: 25px 0px;
    background-color: rgb(237, 133, 7);
    font-family: 600;
}
.rd_m:hover{
    background-color: rgb(4, 79, 230);
    transition: 0.5s;
    cursor: pointer;
}
.sub_title{
    color: darkorange;
    border-bottom: 2px solid orange;
    padding: 8px 0px;
    width: 38%;
}

@media (max-width:767px) {
    .about_wrapper{
        height: auto;
        background-color:#E9EFEC;
        padding: 0;
        width: 100%;
    }
    .sub_title{
        color: darkorange;
        border-bottom: 1px solid rgb(253, 231, 189);
        padding: 0px 0px;
        width: 100%;
        font-size: 0.9em;
    }
    .main_tit{
        font-size: 1.5rem;
        font-weight: 600;
        width: auto;
        word-spacing:inherit;
        color: rgb(15, 78, 101);
        line-height: 38px;
        width: 100%;
        overflow: hidden;
    }
    .about_1{
        gap: 0px;
        padding: 6px 27px;
    }
    .about_2{
        gap: 0px;
        padding: 6px 27px; 
    }
    .full_wrapper{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .para{
        line-height: 27.6px;
        padding: 6vw;
    }
    .about_para{
        width: 100%;
        text-align: justify;
        margin-top: 3vh;
        font-size: 0.9rem;
        color: rgb(99, 99, 99);
        overflow: hidden;
    }
    .rd_m{
        padding: 10px 25px;
        border-radius: 16px;
        color: white;
        font-size: 13px;
        text-align: center;
        border: none;
        margin: 25px 0px;
        background-color: rgb(237, 133, 7);
        font-family: 600;
    }
}