.footer_wrapper{
    background-color:#3489D4;
    height: 65.5vh;
    font-family: system-ui;
    font-size: medium;
}
.first_1{
    height: 32vh;
    width: 40%;
    padding: 20px;
    font-family: system-ui;
}
.footer_flex{
    display: flex;
    gap: 0px;
    padding:30px 100px;

}

/* Rating Img CSS */

/* .ratings-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .rating-image {
    width: 100%;
    height: 40px; 
    object-fit: cover;
  }
   */
 


  .ratings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px;
    background-color: #fff;
}

.rating-image {
    width: auto;  
    height: 40px; 
    object-fit: contain;
}

  
  



.itns{
    padding: 10px 0px;
    text-align: justify;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: system-ui;
}
.addres{
    padding: 10px 0px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: system-ui;
}
.addres:hover{
    color: rgb(255, 255, 255);
    transition:0.9s;
    transform: translateX(4%);
}
.itns:hover{
    color: rgb(255, 255, 255);
    transition:0.9s;
    transform: translateX(4%);
}

.white_loc{
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: system-ui;
    text-decoration: none;
}

.location-icon{
    color:white !important;
}

.locations-link{
    text-decoration: none;
}

/* slider*/
.slider {
    width: 100%;
        overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slides {
    display: flex;
    width: auto; /* Adjust based on the number of slides */
    animation: slide 12s linear infinite;
}

.slide {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28vh;
    font-size: 24px;
    color: #ffffff;
    font-family: system-ui;
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
/* slider ^ */
@media (max-width:767px) {
    .slide {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28vh;
        font-size: 24px;
        color: #ffffff;
        padding: 50px;
    }
    
    .first_1{
        height: auto;
        width: 100%;
        padding: 20px;
    }
    .footer_flex{
        flex-direction: column;
        gap: 0px;
        padding:0px 20px;
        overflow: hidden;
    }
    .footer_wrapper{
        background-color: rgb(0, 46, 87);
        height: auto;
    }
    .slider {
        width: 100%;
            overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .itns{
        padding: 10px 0px;
        text-align: justify;
        color: white;
        font-size: 1rem;
        cursor: pointer;
    }
    .addres{
        padding: 11px 0px;
        color: white;
        font-size: 1rem;
        cursor: pointer;
    }
}
.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
    height:100vh;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}
.slide_flr{
    display: flex;
    gap:40px;
    line-height: 8px;
    flex-wrap: wrap;
    font-size: 14px;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



